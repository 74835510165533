import { makeStyles, Typography, Tooltip } from '@material-ui/core';
import { Calendar, ContratoStatus, PropostaStatus } from '@omega-energia/utilities';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import React from 'react';
import { contratoStatusStyles } from '../PropostasContratos.style';

const useStyles = makeStyles(contratoStatusStyles());

export const PropostaContratoStatus = props => {
  const { contrato } = props;
  const proposta = contrato.propostas.length > 0 ? contrato.propostas[0] : {};
  const dataDeHoje = DateTime.local();
  const dataAceiteContrato = DateTime.fromISO(contrato?.dataAceite).plus({ hours: 3 });
  const dataVencimentoContrato = dataAceiteContrato.plus({ days: 90 });

  const propostaContratoStatuses = Object.freeze({
    CONTRATO_ENCERRADO: 'Contrato Encerrado',
    CONTRATO_VIGENTE: 'Contrato Vigente',
    CONTRATO_ASSINADO: 'Contrato Assinado',
    PROPOSTA_EXCLUIDA: 'Proposta Excluída',
    CONTRATO_VENCIDO: 'Contrato Vencido',
    AGUARDANDO_ASSINATURAS: 'Aguardando Assinatura(s)',
    ASSINATURA_NAO_SOLICITADA: 'Assinatura não solicitada',
    CONTRATO_CANCELADO: 'Contrato Cancelado',
    AGUARDANDO_CONFIRMACAO: 'Aguardando Confirmação',
    EXCEDENTE_AO_CREDITO: 'Excedente ao crédito',
    PROPOSTA_EXPIRADA: 'Proposta Expirada',
    PROPOSTA_VALIDA: 'Proposta Válida',
  });

  const statusContratoProposta = propostaItem => {
    const inicio = DateTime.fromISO(proposta.inicioSuprimento);
    const fim = DateTime.fromISO(proposta.fimSuprimento);

    let status = '';

    if (propostaItem.status === PropostaStatus.CONTRATADO && contrato.status === ContratoStatus.CONTRATADO) {
      if (DateTime.local() > fim) {
        status = propostaContratoStatuses.CONTRATO_ENCERRADO;
      } else if (DateTime.local() >= inicio) {
        status = propostaContratoStatuses.CONTRATO_VIGENTE;
      } else {
        status = propostaContratoStatuses.CONTRATO_ASSINADO;
      }
    } else if (propostaItem.oculta === true) {
      status = propostaContratoStatuses.PROPOSTA_EXCLUIDA;
    } else if (contrato.status === ContratoStatus.AGUARDANDO_ASSINATURA && dataDeHoje > dataVencimentoContrato) {
      status = propostaContratoStatuses.CONTRATO_VENCIDO;
    } else if (contrato.status === ContratoStatus.AGUARDANDO_ASSINATURA) {
      status = propostaContratoStatuses.AGUARDANDO_ASSINATURAS;
    } else if (contrato.status === ContratoStatus.AGUARDANDO_ASSINANTES) {
      status = propostaContratoStatuses.ASSINATURA_NAO_SOLICITADA;
    } else if (proposta.status === PropostaStatus.INVALIDO && contrato.status === ContratoStatus.CANCELADO) {
      status = propostaContratoStatuses.CONTRATO_CANCELADO;
    } else if (contrato.status === ContratoStatus.AGUARDANDO_CONFIRMACAO) {
      status = propostaContratoStatuses.AGUARDANDO_CONFIRMACAO;
    } else if (propostaItem.status === PropostaStatus.AGUARDANDO_ANALISE_CREDITO) {
      status = propostaContratoStatuses.EXCEDENTE_AO_CREDITO;
    } else if (propostaItem.status === PropostaStatus.EXPIRADO) {
      status = propostaContratoStatuses.PROPOSTA_EXPIRADA;
    } else if (propostaItem.status === PropostaStatus.AGUARDANDO_CONTRATACAO) {
      status = propostaContratoStatuses.PROPOSTA_VALIDA;
    }

    return status;
  };

  const status = statusContratoProposta(proposta);

  const styleHandlerStatusContratoProposta = (currentStatus = status) => {
    const isContratoAceito = [
      propostaContratoStatuses.AGUARDANDO_ASSINATURAS,
      propostaContratoStatuses.ASSINATURA_NAO_SOLICITADA,
    ].includes(currentStatus);

    const statusContratoStyle = {
      className: useStyles().rawStatus,
      shouldRenderTooltip: false,
    };

    if (currentStatus === propostaContratoStatuses.CONTRATO_ASSINADO) {
      statusContratoStyle.className = useStyles({ color: 'green' }).main;
    } else if (currentStatus === propostaContratoStatuses.CONTRATO_VENCIDO) {
      statusContratoStyle.className = useStyles({ color: 'black' }).main;
    } else if (isContratoAceito) {
      const cincoDiasUteisPosContratacao = Calendar.dateAfterDiasUteis(5, dataAceiteContrato);
      const quarentaDiasUteisPosContratacao = Calendar.dateAfterDiasUteis(40, dataAceiteContrato);
      let styleProps;

      if (dataDeHoje <= cincoDiasUteisPosContratacao) {
        styleProps = { color: 'green' };
      } else if (dataDeHoje <= quarentaDiasUteisPosContratacao) {
        styleProps = { color: 'orange' };
      } else {
        styleProps = { color: 'red' };
      }
      statusContratoStyle.className = useStyles(styleProps).main;
      statusContratoStyle.shouldRenderTooltip = true;
    }

    return statusContratoStyle;
  };

  const { className, shouldRenderTooltip } = styleHandlerStatusContratoProposta();

  return (
    <div>
      <Tooltip
        className={className}
        title={shouldRenderTooltip ? `Vencimento em ${dataVencimentoContrato.toFormat('dd/MM/yyyy')}` : ''}
      >
        <Typography variant="body2">{status}</Typography>
      </Tooltip>
    </div>
  );
};

PropostaContratoStatus.propTypes = {
  contrato: PropTypes.shape({
    status: PropTypes.string,
    propostas: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        oculta: PropTypes.bool,
      }),
    ),
  }).isRequired,
};
