/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import { PropostaStatus, ContratoStatus, TipoPrazo, TipoFlexibilidade, formatVolume } from '@omega-energia/utilities';
import { TableCell, TableRow, Typography, Box, Collapse, IconButton, Snackbar, Link } from '@material-ui/core';
import { DateTime } from 'luxon';
import { formatCnpj } from '@brazilian-utils/formatters';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  AccessTime,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from 'react-query';
import { FileDownload } from '@omega-energia/react';
import { formatCurrency } from '../../../../helpers';
import { Submercado, Fonte, VariacaoFlexibilidade } from '../../../../helpers/enums';
import { useAuth } from '../../../../auth/authProvider';
import req from '../../../../services/network/request';
import { PropostaContratoDetalhes } from './PropostaContratoDetalhes';
import { PropostaContratoStatus } from './PropostaContratoStatus';
import { contratoRowStyles } from '../PropostasContratos.style';
import { temAtendimentoVolumeIguais } from '../helpers/checkEqualityVolumeAtendimentoCargas';
import { proposalVolume } from '../helpers/calculateVolumeProposal';

export function PropostaContratoRow(props) {
  const {
    contratoData,
    setOpenPagamentoDialog,
    setContratoPagamentoDialog,
    setOpenCargasDialog,
    setDataCargasDialog,
    setOpenHistoricoDialog,
    setDataHistoricoDialog,
    setAlterarPrazoAssinaturaDialog,
    setDataAlterarPrazoAssinaturaDialog,
    changeVolumeAtendimento,
    changePriceAVG,
    setDataCancelarContratoDialog,
    setOpenChangeContratanteDialog,
    setDataChangeContratanteDialog,
    setOpenAlterarDataBaseDialog,
    setOpenCancelarContratoDialog,
    setDataAlterarDataBaseDialog,
    setOpenAssinaturaDialog,
    setDataAssinaturaDialog,
    setOpenJustificativaCancelamentoContratoDialog,
    setDataJustificativaCancelamentoContratoDialog,
  } = props;

  const [open, setOpen] = useState(false);
  const { token } = useAuth();

  const [contrato, setContrato] = useState(contratoData);

  const [alert, setAlert] = useState({ show: false, msg: '' });

  function handleCloseAlert() {
    setAlert({ show: false, message: '' });
  }

  function updateVolumeAtendimento(propostaCargaPeriodoId, isVolume, tipoFlexibilidade, data) {
    const contratoUpdate = { ...contrato };
    Object.entries(contrato.propostas).forEach(([keyProposta, propostaDoContrato]) => {
      Object.entries(propostaDoContrato.propostaCargas).forEach(([keyPropostaCarga, propostaCarga]) => {
        Object.entries(propostaCarga.propostaCargaPeriodo).forEach(
          ([keyPropostaCargaPeriodo, propostaCargaPeriodo]) => {
            if (propostaCargaPeriodo.id === propostaCargaPeriodoId) {
              if (isVolume) {
                if (tipoFlexibilidade === 'Total') {
                  contratoUpdate.propostas[keyProposta].propostaCargas[keyPropostaCarga].propostaCargaPeriodo[
                    keyPropostaCargaPeriodo
                  ].volumeReferencialCarga = data.volumeReferencialCarga;
                } else {
                  contratoUpdate.propostas[keyProposta].propostaCargas[keyPropostaCarga].propostaCargaPeriodo[
                    keyPropostaCargaPeriodo
                  ].volumeCarga = data.volumeCarga;
                }
              } else {
                contratoUpdate.propostas[keyProposta].propostaCargas[keyPropostaCarga].propostaCargaPeriodo[
                  keyPropostaCargaPeriodo
                ].atendimentoCarga = data.atendimentoCarga;
              }
            }
          },
        );
      });
    });
    setContrato(contratoUpdate);
    changeVolumeAtendimento(contratoUpdate);
  }

  function changePrice(precoPeriodoId, price) {
    const contratoUpdate = contrato;
    Object.entries(contrato.propostas).forEach(([keyProposta, propostaDoContrato]) => {
      Object.entries(propostaDoContrato.precosPeriodo).forEach(([keyPrecoPeriodo, precoPeriodo]) => {
        if (precoPeriodo.id === precoPeriodoId) {
          contratoUpdate.propostas[keyProposta].precosPeriodo[keyPrecoPeriodo].precoMwh = price;
          changePriceAVG(contratoUpdate.propostas[keyProposta]);
        }
      });
    });
    setContrato(contratoUpdate);
  }

  const useRowStyles = makeStyles(contratoRowStyles);

  const classes = useRowStyles();

  const proposta = contrato.propostas.length > 0 ? contrato.propostas[0] : {};

  const dadosAssinantesContratante = contrato.contratoAssinaturas?.length > 0 ? contrato.contratoAssinaturas : {};
  const dadosAssinantesOmega = contrato?.ourRequestClickSignKey ? contrato.ourRequestClickSignKey : {};

  const formatarVolume = () => {
    if (proposta.tipoPrazo === TipoPrazo.CURTO_PRAZO) {
      return `${formatVolume(proposta.volumeMWh)} MWh`;
    }

    switch (proposta.tipoFlexibilidade) {
      case TipoFlexibilidade.FLEX_PADRAO:
        return `${formatVolume(proposalVolume(proposta.propostaCargas))} MWm`;
      default:
        return '';
    }
  };

  function getTipoOuVariacaoFlexibilidade({ tipoFlexibilidade, variacao }) {
    if (!tipoFlexibilidade) {
      return 'Flat';
    }

    if (tipoFlexibilidade === TipoFlexibilidade.FLEX_PADRAO) {
      return `Flex Básica ${VariacaoFlexibilidade.toPercentual(variacao)}`;
    }

    return 'Flex total';
  }

  const inicioSuprimento = DateTime.fromISO(proposta.inicioSuprimento).plus({ hours: 3 });
  const fimSuprimento = DateTime.fromISO(proposta.fimSuprimento).minus({ days: 1 });

  let periodoProposta = '';

  if (inicioSuprimento.month === fimSuprimento.month && inicioSuprimento.year === fimSuprimento.year) {
    periodoProposta = inicioSuprimento.toFormat('LLL yyyy');
  } else if (
    inicioSuprimento.month === 1 &&
    fimSuprimento.month === 12 &&
    inicioSuprimento.year === fimSuprimento.year
  ) {
    periodoProposta = inicioSuprimento.toFormat('yyyy');
  } else {
    periodoProposta = `${inicioSuprimento.toFormat('LLL yyyy')} - ${fimSuprimento.toFormat('LLL yyyy')}`;
  }

  const [savePrice] = useMutation(
    postData =>
      req.put(
        `/backstage/preco-periodo/${postData.id}`,
        {
          precoMwh: postData.precoMwh,
        },
        { token },
      ),
    {
      onSuccess: () => {
        setAlert({
          show: true,
          msg: 'Preço da proposta alterado',
        });
      },
      onError: error => {
        setAlert({ show: true, msg: error.message });
      },
    },
  );

  const ucs = contrato?.propostas[0]?.propostaCargas;

  async function baixarContrato() {
    let file;
    if (contrato) {
      if (
        [
          ContratoStatus.AGUARDANDO_ASSINANTES,
          ContratoStatus.AGUARDANDO_ASSINATURA,
          ContratoStatus.CONTRATADO,
          ContratoStatus.AGUARDANDO_CONFIRMACAO,
          ContratoStatus.CANCELADO,
        ].includes(contrato.status)
      ) {
        file = await req.get(`/backstage/contrato/${contrato.id}/download`, { token, responseType: 'blob' });
      } else {
        file = await req.get(`/backstage/contrato/minuta-padrao`, {
          params: { fonteEnergia: proposta.fonteEnergia, tipoFlexibilidade: proposta.tipoFlexibilidade },
          token,
          responseType: 'blob',
        });
      }
    }

    FileDownload.downloadFile(file, 'contrato');
  }

  async function baixarProposta() {
    let file;
    if (contrato) {
      file = await req.get(`/backstage/proposta/${contrato.propostas[0].id}/download`, { token, responseType: 'blob' });
    }

    FileDownload.downloadFile(file, 'proposta');
  }

  const verificaDataComHorarioDeVerao = data => {
    const isDaylightSaving = DateTime.fromISO(data).setZone('America/Sao_Paulo').isInDST;
    if (isDaylightSaving) {
      return DateTime.fromISO(data)
        .setZone('America/Sao_Paulo')
        .minus({ hours: 1 })
        .toFormat("dd/LL 'às' HH:mm");
    }
    return DateTime.fromISO(data)
      .setZone('America/Sao_Paulo')
      .toFormat("dd/LL 'às' HH:mm");
  };

  const formatDataBase = dataBase => {
    return DateTime.fromISO(dataBase)
      .setZone('America/Sao_Paulo')
      .toFormat('dd LLL yyyy');
  };

  const aguardandoDadosAssinatura = [
    ContratoStatus.AGUARDANDO_ASSINATURA,
    ContratoStatus.AGUARDANDO_ASSINANTES,
  ].includes(contrato.status);

  const aguardandoContratacao =
    aguardandoDadosAssinatura === false && proposta.status === PropostaStatus.AGUARDANDO_CONTRATACAO;

  const handleAssinaturaDialog = (dadosAssinantes, showSignature) => {
    dadosAssinantes.showSignature = showSignature;
    setDataAssinaturaDialog(dadosAssinantes);
    setOpenAssinaturaDialog(true);
  };

  const shouldShowSignatureLink = contrato.status === ContratoStatus.AGUARDANDO_ASSINATURA;
  const shouldDisableModalButton = contrato.status === ContratoStatus.AGUARDANDO_ASSINANTES;

  return (
    <>
      <TableRow className={cls(open ? classes.root : '', classes.tr)}>
        <TableCell className={classes.tableCell} width="3%">
          <Typography variant="body2" className={classes.textCapitalize}>
            {proposta.tipoOperacao.toLowerCase()}
          </Typography>
        </TableCell>

        <TableCell className={classes.tableCell} width="27%">
          <Typography variant="body2">{contrato?.empresaContratante?.nome || proposta.empresaMae.nome}</Typography>
          <Typography>
            <Box display="flex" component="span" className={classes.orgLine}>
              <Box display="flex" alignItems="center" component="span">
                <Typography variant="caption" className={classes.cnpjCaption}>
                  {formatCnpj(contrato?.empresaContratante?.cnpj || proposta.empresaMae.cnpj)}
                </Typography>
              </Box>
            </Box>
          </Typography>
        </TableCell>

        <TableCell className={classes.tableCell} width="32%">
          <Typography variant="body2">
            {`${periodoProposta} ${Submercado.toAbbrString(proposta.submercado)} ${Fonte.toString(
              proposta.fonteEnergia,
            )}
            ${temAtendimentoVolumeIguais(ucs, 'volumeCarga') ? formatarVolume() : 'Múltiplos Volumes'}
            ${getTipoOuVariacaoFlexibilidade(proposta)}`}
          </Typography>
        </TableCell>

        <TableCell className={classes.tableCell} width="11%">
          <Typography variant="body2">{`${formatCurrency(proposta.precoMedio, 2)}/MWh`}</Typography>
        </TableCell>

        <TableCell className={classes.tableCell} width="18%">
          <div className={classes.tableCellStatus}>
            <PropostaContratoStatus contrato={contrato} />
          </div>
        </TableCell>

        <TableCell className={classes.tableCell} width="8%">
          <Typography variant="body2">{formatDataBase(contrato?.propostas[0]?.dataBase) || '-'}</Typography>
        </TableCell>

        <TableCell className={classes.tableCell} width="1%">
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow className={classes.tr} style={{ display: open ? 'contents' : 'none' }}>
        <TableCell colSpan={7} className={classes.tableCellPaddding}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Box display="flex" justifyContent={aguardandoContratacao ? 'flex-end' : 'space-between'} px={2}>
                {aguardandoContratacao && (
                  <Typography gutterBottom>
                    Proposta expira em{' '}
                    <Typography component="strong" color="primary">
                      {verificaDataComHorarioDeVerao(proposta.dataValidade)}
                    </Typography>
                  </Typography>
                )}
                {aguardandoDadosAssinatura && (
                  <>
                    <Box>
                      <Typography
                        align="left"
                        variant="subtitle2"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        Requisitos do contrato:
                        <AccessTime
                          className={
                            shouldDisableModalButton ? classes.disabledAccessTimeStyle : classes.accessTimeStyle
                          }
                          color="primary"
                        />
                        <Typography component="strong">
                          <Link
                            component="button"
                            variant="body2"
                            color="primary"
                            className={shouldDisableModalButton ? classes.disabledButton : ''}
                            disabled={shouldDisableModalButton}
                            onClick={() => {
                              handleAssinaturaDialog(dadosAssinantesContratante, shouldShowSignatureLink);
                            }}
                          >
                            Assinatura
                          </Link>
                        </Typography>
                        <AccessTime className={classes.accessTimeStyle} color="primary" />
                        <Typography component="strong">
                          <Link
                            component="button"
                            variant="body2"
                            color="primary"
                            onClick={() => {
                              handleAssinaturaDialog(dadosAssinantesOmega, shouldShowSignatureLink);
                            }}
                          >
                            Assinatura Serena
                          </Link>
                        </Typography>
                      </Typography>
                    </Box>
                    <Typography align="right" gutterBottom>
                      Prazo para assinatura:{' '}
                      <Typography component="strong" color="primary">
                        {verificaDataComHorarioDeVerao(contrato.dataValidade)}
                      </Typography>
                    </Typography>
                  </>
                )}
              </Box>
              <PropostaContratoDetalhes
                contrato={contrato}
                onBaixarContrato={() => {
                  baixarContrato();
                }}
                onBaixarProposta={() => {
                  baixarProposta();
                }}
                onRenegociarPagamento={() => {
                  setContratoPagamentoDialog({
                    id: contrato.id,
                    diasUteisPagamento: contrato.diasUteisPagamento,
                  });
                  setOpenPagamentoDialog(true);
                }}
                onAlterarPrazoAssinatura={() => {
                  setAlterarPrazoAssinaturaDialog(true);
                  setDataAlterarPrazoAssinaturaDialog({
                    contrato: {
                      id: contrato.id,
                      dataValidade: contrato.dataValidade,
                    },
                  });
                }}
                updateVolumeAtendimento={updateVolumeAtendimento}
                onChangePrice={(propostaSelecionada, preco, novoPreco) => {
                  if (novoPreco !== propostaSelecionada.precoMwh) {
                    savePrice({ id: preco.id, precoMwh: novoPreco });
                    changePrice(preco.id, novoPreco);
                  }
                }}
                onHistoricoAlteracoes={() => {
                  setDataHistoricoDialog(contrato.id);
                  setOpenHistoricoDialog(true);
                }}
                onOpenCargas={cargasDaPropostaSelecionada => {
                  setDataCargasDialog(cargasDaPropostaSelecionada);
                  setOpenCargasDialog(true);
                }}
                onAlterarContratante={() => {
                  setDataChangeContratanteDialog(contrato.id);
                  setOpenChangeContratanteDialog(true);
                }}
                onAlterarDataBase={() => {
                  setDataAlterarDataBaseDialog({
                    id: contrato.propostas[0].id,
                    dataBase: contrato.propostas[0].dataBase,
                  });
                  setOpenAlterarDataBaseDialog(true);
                }}
                onCancelarContrato={() => {
                  setOpenCancelarContratoDialog(true);
                  setDataCancelarContratoDialog({
                    id: contrato.id,
                    fonte: contrato.propostas[0].fonteEnergia,
                    variacao: contrato.propostas[0].variacao,
                    inicioSuprimento: contrato.propostas[0].inicioSuprimento,
                    fimSuprimento: contrato.propostas[0].fimSuprimento,
                    volume: contrato.propostas[0].volume,
                    percentualAtendimento: contrato.propostas[0].percentualAtendimento,
                    nomeDaEmpresa: contrato.empresaContratante.nome,
                  });
                }}
                onShowJustificativaCancelamentoContrato={() => {
                  setOpenJustificativaCancelamentoContratoDialog(true);
                  setDataJustificativaCancelamentoContratoDialog(contrato.justificativa);
                }}
              />
              <Snackbar
                open={alert.show}
                autoHideDuration={5000}
                message={alert.msg}
                onClose={() => {
                  handleCloseAlert();
                }}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
PropostaContratoRow.propTypes = {
  setOpenPagamentoDialog: PropTypes.func.isRequired,
  setContratoPagamentoDialog: PropTypes.func.isRequired,
  setOpenCargasDialog: PropTypes.func.isRequired,
  setDataCargasDialog: PropTypes.func.isRequired,
  contratoData: PropTypes.shape({
    propostas: PropTypes.array,
    status: PropTypes.string,
    id: PropTypes.string,
    diasUteisPagamento: PropTypes.number,
  }).isRequired,
  setOpenHistoricoDialog: PropTypes.func.isRequired,
  setDataHistoricoDialog: PropTypes.func.isRequired,
  setAlterarPrazoAssinaturaDialog: PropTypes.func.isRequired,
  setDataAlterarPrazoAssinaturaDialog: PropTypes.func.isRequired,
  changeVolumeAtendimento: PropTypes.func.isRequired,
  changePriceAVG: PropTypes.func.isRequired,
  setDataChangeContratanteDialog: PropTypes.func.isRequired,
  setOpenChangeContratanteDialog: PropTypes.func.isRequired,
  setDataAlterarDataBaseDialog: PropTypes.func.isRequired,
  setOpenAlterarDataBaseDialog: PropTypes.func.isRequired,
  setOpenAssinaturaDialog: PropTypes.func.isRequired,
  setDataAssinaturaDialog: PropTypes.func.isRequired,
  setDataCancelarContratoDialog: PropTypes.func.isRequired,
};
