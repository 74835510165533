import { TabsURL, GruposBackstage } from '../../helpers/enums';

export const BackstagePages = Object.freeze({
  [GruposBackstage.CONTRATACAO]: {
    tabs: [TabsURL.CONTRATACAO__COTACAO, TabsURL.CONTRATACAO__MIGRACAO_AZ],
  },
  [GruposBackstage.PRECOS]: {
    tabs: [
      TabsURL.PRECO__CURVA_CP,
      TabsURL.PRECO__PRECOS,
      TabsURL.PRECO__PRECOS_CAMPANHA,
      TabsURL.PRECO__PRECOS_PPA,
      TabsURL.PRECO__PREMIOS,
    ],
  },
  [GruposBackstage.NEGOCIOS]: {
    tabs: [
      TabsURL.NEGOCIOS__FATURAMENTO_REGISTRO,
      TabsURL.NEGOCIOS__PROPOSTAS_CONTRATOS,
      '/negocios/geracao-distribuida',
      TabsURL.NEGOCIOS__MIGRACAO_AZ,
      TabsURL.NEGOCIOS__PROPOSTAS_AZ,
    ],
  },
  [GruposBackstage.PARAMETROS]: {
    tabs: [
      TabsURL.PARAMETROS__CREDITO,
      TabsURL.PARAMETROS__DISTRIBUIDORA_C0RTE,
      TabsURL.PARAMETROS__DISTRIBUIDORA_DESCONTO,
      TabsURL.PARAMETROS__MIGRACAOAZ,
      TabsURL.PARAMETROS__GERACAO_DISTRIBUIDA,
      TabsURL.PARAMETROS__ICMS,
      TabsURL.PARAMETROS__INFOMERCADO,
      TabsURL.PARAMETROS__IPCA,
      TabsURL.PARAMETROS__PRAZOS,
      TabsURL.PARAMETROS__PREMIOS,
      TabsURL.PARAMETROS__TARIFAS_DISTRIBUICAO,
    ],
  },
  [GruposBackstage.GD]: {
    tabs: [TabsURL.GD__VALIDACAO],
  },
});

export const permissionCodesList = Object.freeze({
  '/base-usuarios/pre-cadastro': {
    listar: ['PreCadastro.Criar'],
    editar: ['PreCadastro.Criar'],
  },
  '/base-usuarios/pre-cadastro/novo': {
    listar: ['PreCadastro.Criar'],
    editar: ['PreCadastro.Criar'],
  },
  [TabsURL.CONTRATACAO__COTACAO]: {
    listar: ['InfoAgente.ListarCargas', 'Preco.CalcularCotacao'],
    editar: ['Proposta.CriarPropostaComFatiamento', 'Cliente.CadastrarEmpresa'],
  },
  [TabsURL.CONTRATACAO__MIGRACAO_AZ]: {
    listar: ['MigracaoAz.AcessarContrato'],
    editar: ['MigracaoAz.CriarContrato'],
  },
  [TabsURL.PRECO__CURVA_CP]: {
    listar: ['Proposta.ListarPrecosCurtoPrazo'],
    editar: [
      'Proposta.AtualizarAgioCurtoPrazo',
      'Proposta.CriarPLDMedioCurtoPrazo',
      'Proposta.AtualizarPLDMedioCurtoPrazo',
    ],
  },
  [TabsURL.PRECO__PRECOS]: {
    listar: ['Preco.ListarPrecosCurvaForward', 'Preco.BaixarPrecosCurvaForward', 'Preco.ListarForwardVolatil'],
    editar: [
      'Preco.CriarCurvaForwardAnual',
      'Preco.CriarPrecoCurvaForward',
      'Preco.CriarPrecoCurvaForwardAnual',
      'Preco.CriarSpreadCurvaForward',
      'Preco.CriarSpreadAnualCurvaForward',
      'Preco.CriarForwardVolatil',
    ],
  },
  [TabsURL.PRECO__PRECOS_CAMPANHA]: {
    listar: ['Preco.ListarPrecoCampanha'],
    editar: ['Preco.CriarPrecoCampanha'],
  },
  [TabsURL.PRECO__PRECOS_PPA]: {
    listar: ['Preco.ListarPrecoPPA'],
    editar: ['Preco.CriarPrecoPPA'],
  },
  [TabsURL.PRECO__PREMIOS]: {
    listar: ['Preco.ListarPrecoPremio'],
    editar: ['Preco.CriarPrecoPremio'],
  },
  [TabsURL.NEGOCIOS__FATURAMENTO_REGISTRO]: {
    listar: ['Faturamento.ListarNotasFiscais', 'Storage.BaixarArquivos'],
    editar: ['Faturamento.AtualizarInforme', 'Storage.CriarNotaFiscal'],
  },
  [TabsURL.NEGOCIOS__MIGRACAO_AZ]: {
    listar: ['MigracaoAz.AcessarContrato'],
    editar: ['MigracaoAz.CriarContrato', 'MigracaoAz.CancelarContrato'],
  },
  [TabsURL.NEGOCIOS__PROPOSTAS_AZ]: {
    listar: ['MigracaoAz.AcessarContrato'],
    editar: ['MigracaoAz.CriarContrato', 'MigracaoAz.CancelarContrato'],
  },
  [TabsURL.NEGOCIOS__PROPOSTAS_CONTRATOS]: {
    listar: [
      'Proposta.ListarPrecoOriginal',
      'Contrato.ListarContratos',
      'Contrato.ListarContratantes',
      'Contrato.BaixarContrato',
      'Contrato.GerarLinkAssinatura',
      'Contrato.BaixarMinutaPadrao',
      'Contrato.ListarResumo',
      'Proposta.BaixarProposta',
      'Usuario.ListarHistoricoAlteracao',
    ],
    editar: [
      'Contrato.AtualizarContratante',
      'Contrato.AtualizarDataValidade',
      'Contrato.AlterarDataVencimento',
      'Contrato.CancelarContrato',
      'PrecoPeriodo.AtualizarPreco',
      'Proposta.AtualizarDataBase',
      'Proposta.AtualizarVolumeAtendimentoCarga',
    ],
  },
  [TabsURL.PARAMETROS__CREDITO]: {
    listar: ['Credito.ListarParametros', 'Credito.ListarRating', 'GeracaoDistribuida.ListarCreditoAutomatico'],
    editar: [
      'Credito.CriarChoque',
      'Credito.CriarLimiteCreditoAutomatico',
      'Credito.CriarPesos',
      'Credito.CriarRating',
      'GeracaoDistribuida.AtualizarCreditoAutomatico',
    ],
  },
  [TabsURL.PARAMETROS__DISTRIBUIDORA_C0RTE]: {
    listar: ['Distribuidora.ListarDistribuidorasMigracao'],
    editar: ['Distribuidora.ExcluirDistribuidoraMigracao', 'Distribuidora.CriarDistribuidoraMigracao'],
  },
  [TabsURL.PARAMETROS__DISTRIBUIDORA_DESCONTO]: {
    listar: [],
    editar: ['Distribuidora.CriarDescontos'],
  },
  [TabsURL.PARAMETROS__ICMS]: {
    listar: ['Tributacao.ListarICMS', 'Tributacao.ListarUF', 'Tributacao.ListarHistoricoUF'],
    editar: ['Tributacao.CriarICMS'],
  },
  [TabsURL.PARAMETROS__INFOMERCADO]: {
    listar: ['Infomercado.ListarAtualizacoes'],
    editar: ['Infomercado.CriarProcessamentoPlanilha', 'Storage.CriarImportacaoInfomercado'],
  },
  [TabsURL.PARAMETROS__IPCA]: {
    listar: ['Financeiro.ListarIPCA'],
    editar: ['Financeiro.AtualizarIPCA'],
  },
  [TabsURL.PARAMETROS__MIGRACAOAZ]: {
    listar: ['MigracaoAz.ListarParametro'],
    editar: ['MigracaoAz.CriarParametro'],
  },
  [TabsURL.PARAMETROS__GERACAO_DISTRIBUIDA]: {
    listar: ['GeracaoDistribuida.ListarParametros'],
    editar: ['GeracaoDistribuida.CriarParametro', 'GeracaoDistribuida.ExcluirParametro'],
  },
  [TabsURL.PARAMETROS__PRAZOS]: {
    listar: ['Proposta.ListarHistoricoCurtoPrazo', 'Contrato.ListarDataPagamento', 'Proposta.ListarPeriodoCurtoPrazo'],
    editar: ['Proposta.CriarPeriodoCurtoPrazo', 'Contrato.CriarDataPagamento'],
  },
  [TabsURL.PARAMETROS__PREMIOS]: {
    listar: ['ParametroPremio.ListarConsumoAgregado'],
    editar: ['ParametroPremio.CriarConsumoAgregado'],
  },
  [TabsURL.PARAMETROS__TARIFAS_DISTRIBUICAO]: {
    listar: [
      'TarifaDistribuicao.BaixarModelo',
      'TarifaDistribuicao.BaixarTarifasDistribuicao',
      'TarifaDistribuicao.ListarParametrosTarifaDistribuicao',
    ],
    editar: ['TarifaDistribuicao.CriarTarifasDistribuidoras'],
  },
  [TabsURL.PARAMETROS__PIS_COFINS]: {
    listar: [],
    editar: [],
  },
  [TabsURL.GD__VALIDACAO]: {
    listar: ['Validacao.ListarClientes', 'Validacao.DownloadContadeLuzContrato'],
    editar: ['Validacao.Atualizar'],
  },
});
