import React, { useState } from 'react';
import cls from 'classnames';
import PropTypes from 'prop-types';
import { Energia } from '@omega-energia/utilities';
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { DateTime, Interval } from 'luxon';
import { makeStyles } from '@material-ui/core/styles';
import { Portlet, PortletContent } from '../../../../components';
import styles from './PropostasContratosTable.styles';
import {
  HistoricoDialog,
  PagamentoDialog,
  RescisaoDialog,
  AlterarPrazoAssinaturaDialog,
  AlterarDataBaseDialog,
  CancelarContratoDialog,
} from './PropostasContratosDialogs/PropostasContratosDialogs';
import CargasDialog from './PropostasContratosDialogs/CargasDialog/CargasDialog';
import AssinaturaDialog from './PropostasContratosDialogs/AssinaturaDialog/AssinaturaDialog';
import { PropostaContratoRow } from './PropostaContratoRow';
import ContratantesDialog from './PropostasContratosDialogs/ContratantesDialog/ContratantesDialog';
import PropostasContratosTablePagination from './components/PropostasContratosTablePagination';
import TableColumnSortButton from '../../../../theme/components/TableColumnSortButton/TableColumnSortButton';
import { JustificativaCancelamentoContratoDialog } from './PropostasContratosDialogs/JustificativaCancelamentoContratoDialog/JustificativaCancelamentoContratoDialog';

const useStyles = makeStyles(styles);

function PropostasContratosTable(props) {
  const classes = useStyles(props);
  const {
    data: contratos,
    setContratos,
    sorts,
    setSorts,
    total,
    currentPage,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  const rootClassName = cls(classes.root);
  const [openPagamentoDialog, setOpenPagamentoDialog] = useState(false);
  const [contratoPagamentoDialog, setContratoPagamentoDialog] = useState({});
  const [openCargasDialog, setOpenCargasDialog] = useState(false);
  const [openRescisaoDialog, setOpenRescisaoDialog] = useState(false);
  const [dataCancelarContratoDialog, setDataCancelarContratoDialog] = useState({});
  const [dataJustificativaCancelamentoContratoDialog, setDataJustificativaCancelamentoContratoDialog] = useState('');
  const [dataCargasDialog, setDataCargasDialog] = useState([]);
  const [openHistoricoDialog, setOpenHistoricoDialog] = useState(false);
  const [dataHistoricoDialog, setDataHistoricoDialog] = useState('');
  const [alterarPrazoAssinaturaDialog, setAlterarPrazoAssinaturaDialog] = useState(false);
  const [openCancelarContratoDialog, setOpenCancelarContratoDialog] = useState(false);
  const [openJustificativaCancelamentoContratoDialog, setOpenJustificativaCancelamentoContratoDialog] = useState(false);
  const [dataAlterarPrazoAssinaturaDialog, setDataAlterarPrazoAssinaturaDialog] = useState({});
  const [openChangeContratanteDialog, setOpenChangeContratanteDialog] = useState(false);
  const [dataChangeContratanteDialog, setDataChangeContratanteDialog] = useState('');
  const [openAssinaturaDialog, setOpenAssinaturaDialog] = useState(false);
  const [dataAssinaturaDialog, setDataAssinaturaDialog] = useState({});

  const [openAlterarDataBaseDialog, setOpenAlterarDataBaseDialog] = useState(false);
  const [dataAlterarDataBaseDialog, setDataAlterarDataBaseDialog] = useState({});

  function changeDueDays(contractId, days) {
    const contractsUpdate = contratos;
    Object.entries(contratos).forEach(([key, contract]) => {
      if (contract.id === contractId) {
        contractsUpdate[key].diasUteisPagamento = days;
      }
    });
    setContratos(contractsUpdate);
  }

  function changeVolumeAtendimento(contrato) {
    const contractsUpdate = contratos;
    Object.entries(contratos).forEach(([key, contract]) => {
      if (contract.id === contrato.id) {
        contractsUpdate[key] = contrato;
      }
    });

    setContratos(contractsUpdate);
  }

  function changePriceAVG(proposta) {
    const precosPorPeriodo = [];

    proposta.precosPeriodo.forEach(montaPeriodo => {
      const periodo = Interval.fromDateTimes(
        DateTime.fromFormat(montaPeriodo.dataInicio, 'yyyy-MM-dd'),
        DateTime.fromFormat(montaPeriodo.dataFim, 'yyyy-MM-dd'),
      );
      precosPorPeriodo.push({
        periodo,
        precoMwh: montaPeriodo.precoMwh,
      });
    });

    const precoMedioPonderado = Energia.mediaPonderadaPrecoPorHora(precosPorPeriodo).toNumber();

    const contractsUpdate = contratos;

    Object.entries(contratos).forEach(([key, contract]) => {
      Object.entries(contract.propostas).forEach(([keyProposta, itemProposta]) => {
        if (itemProposta.id === proposta.id) {
          contractsUpdate[key].propostas[keyProposta].precoMedio = precoMedioPonderado;
        }
      });
    });

    setContratos(contractsUpdate);
  }

  function changeValidityDate(contractId, newDate) {
    const contractsUpdate = contratos;
    Object.entries(contratos).forEach(([key, contract]) => {
      if (contract.id === contractId) {
        contractsUpdate[key].dataValidade = newDate;
      }
    });
    setContratos([...contractsUpdate]);
  }

  return (
    <Portlet className={rootClassName}>
      <PortletContent noPadding>
        <Table width="100%" className={classes.mainTable}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell} width="3%">
                <Typography variant="body2">Tipo</Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="27%">
                <Typography variant="body2">
                  Contraparte
                  <TableColumnSortButton
                    value={sorts?.contraparte || ''}
                    onChange={sort => setSorts({ contraparte: sort })}
                  />
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="32%">
                <Typography variant="body2">
                  Produto
                  <TableColumnSortButton value={sorts?.produto || ''} onChange={sort => setSorts({ produto: sort })} />
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="11%">
                <Typography variant="body2">
                  Preço médio
                  <TableColumnSortButton
                    value={sorts?.precoMedio || ''}
                    onChange={sort => setSorts({ precoMedio: sort })}
                  />
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="18%">
                <Typography variant="body2">
                  Status
                  <TableColumnSortButton value={sorts?.status || ''} onChange={sort => setSorts({ status: sort })} />
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="8%">
                <Typography variant="body2">
                  Data base
                  <TableColumnSortButton
                    value={sorts?.dataBase || ''}
                    onChange={sort => setSorts({ dataBase: sort })}
                  />
                </Typography>
              </TableCell>

              <TableCell className={classes.tableCell} width="1%" />
            </TableRow>
          </TableHead>

          <TableBody>
            {!contratos && (
              <TableRow width="100%" className={classes.tr}>
                <TableCell className={classes.tableCell} width="100%">
                  Ops. Não encontramos nada.
                </TableCell>
              </TableRow>
            )}
            {contratos.map(row => {
              return (
                <PropostaContratoRow
                  key={row.id}
                  contratoData={row}
                  classes={classes}
                  setOpenPagamentoDialog={setOpenPagamentoDialog}
                  setContratoPagamentoDialog={setContratoPagamentoDialog}
                  setOpenCargasDialog={setOpenCargasDialog}
                  setDataCargasDialog={setDataCargasDialog}
                  setOpenRescisaoDialog={setOpenRescisaoDialog}
                  setOpenHistoricoDialog={setOpenHistoricoDialog}
                  setDataCancelarContratoDialog={setDataCancelarContratoDialog}
                  setDataHistoricoDialog={setDataHistoricoDialog}
                  setAlterarPrazoAssinaturaDialog={setAlterarPrazoAssinaturaDialog}
                  setDataAlterarPrazoAssinaturaDialog={setDataAlterarPrazoAssinaturaDialog}
                  changeVolumeAtendimento={changeVolumeAtendimento}
                  changePriceAVG={changePriceAVG}
                  setOpenChangeContratanteDialog={setOpenChangeContratanteDialog}
                  setDataChangeContratanteDialog={setDataChangeContratanteDialog}
                  setOpenAlterarDataBaseDialog={setOpenAlterarDataBaseDialog}
                  setDataAlterarDataBaseDialog={setDataAlterarDataBaseDialog}
                  setOpenAssinaturaDialog={setOpenAssinaturaDialog}
                  setDataAssinaturaDialog={setDataAssinaturaDialog}
                  setOpenCancelarContratoDialog={setOpenCancelarContratoDialog}
                  setOpenJustificativaCancelamentoContratoDialog={setOpenJustificativaCancelamentoContratoDialog}
                  setDataJustificativaCancelamentoContratoDialog={setDataJustificativaCancelamentoContratoDialog}
                />
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={currentPage - 1}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage="Registros por página"
                onChangePage={() => {}}
                ActionsComponent={() => (
                  <PropostasContratosTablePagination
                    total={total}
                    onChangePage={handleChangePage}
                    page={currentPage}
                    rowsPerPage={rowsPerPage}
                  />
                )}
              />
            </TableRow>
          </TableFooter>
        </Table>
        <CancelarContratoDialog
          show={openCancelarContratoDialog}
          onClose={() => {
            setOpenCancelarContratoDialog(pre => !pre);
          }}
          data={dataCancelarContratoDialog}
        />
        <JustificativaCancelamentoContratoDialog
          show={openJustificativaCancelamentoContratoDialog}
          onClose={() => {
            setOpenJustificativaCancelamentoContratoDialog(pre => !pre);
          }}
          helperText={dataJustificativaCancelamentoContratoDialog}
        />
        <PagamentoDialog
          show={openPagamentoDialog}
          onClose={status => {
            setOpenPagamentoDialog(status);
          }}
          contrato={contratoPagamentoDialog}
          changeDueDays={changeDueDays}
        />
        <CargasDialog
          data={dataCargasDialog}
          show={openCargasDialog}
          onClose={status => {
            setOpenCargasDialog(status);
          }}
        />
        <RescisaoDialog
          show={openRescisaoDialog}
          onClose={status => {
            setOpenRescisaoDialog(status);
          }}
        />
        <HistoricoDialog
          data={dataHistoricoDialog}
          show={openHistoricoDialog}
          onClose={status => {
            setOpenHistoricoDialog(status);
          }}
        />
        <ContratantesDialog
          data={dataChangeContratanteDialog}
          show={openChangeContratanteDialog}
          onClose={status => {
            setOpenChangeContratanteDialog(status);
          }}
        />
        <AlterarPrazoAssinaturaDialog
          data={dataAlterarPrazoAssinaturaDialog}
          show={alterarPrazoAssinaturaDialog}
          changeValidityDate={changeValidityDate}
          onClose={() => setAlterarPrazoAssinaturaDialog(false)}
        />
        <AssinaturaDialog
          data={dataAssinaturaDialog}
          show={openAssinaturaDialog}
          onClose={() => setOpenAssinaturaDialog(false)}
        />
        <AlterarDataBaseDialog
          setContratos={(propostaId, novaDataBase) => {
            const arrayAuxiliar = [];
            contratos.forEach(contrato => {
              contrato.propostas.forEach(proposta => {
                if (proposta.id === propostaId) {
                  proposta.dataBase = novaDataBase;
                }
              });
              arrayAuxiliar.push(contrato);
            });
            setContratos(arrayAuxiliar);
          }}
          data={dataAlterarDataBaseDialog}
          show={openAlterarDataBaseDialog}
          onClose={status => {
            setOpenAlterarDataBaseDialog(status);
          }}
        />
      </PortletContent>
    </Portlet>
  );
}

PropostasContratosTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  setContratos: PropTypes.func.isRequired,
  sorts: PropTypes.PropTypes.shape({
    updatedAt: PropTypes.string,
    status: PropTypes.string,
    precoMedio: PropTypes.string,
    produto: PropTypes.string,
    contraparte: PropTypes.string,
    dataBase: PropTypes.string,
  }),
  setSorts: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};

PropostasContratosTable.defaultProps = {
  sorts: {
    updatedAt: 'DESC',
    status: '',
    precoMedio: '',
    produto: '',
    contraparte: '',
    dataBase: '',
  },
};

export default PropostasContratosTable;
