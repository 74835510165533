import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useMutation, queryCache } from 'react-query';
import { req } from '@omega-energia/react';
import { formatCNPJ } from '@brazilian-utils/brazilian-utils';
import CancelarContratoMigracaoAzDialogStyle from './CancelarContratoMigracaoAzDialog.style';
import { useAuth } from '../../../../auth/authProvider';

const useStyles = makeStyles(CancelarContratoMigracaoAzDialogStyle);

export const CancelarContratoMigracaoAzDialog = props => {
  const { show, onClose, data } = props;

  const [alert, setAlert] = useState({ show: false, msg: '' });

  const { token } = useAuth();

  const classes = useStyles();

  const [cancelContract] = useMutation(
    id =>
      req.patch(
        `migracao-az/contratos/bs/${id}/cancelContract`,
        { data },
        {
          token,
        },
      ),
    {
      onSuccess: () => {
        setAlert({ show: true, msg: 'Contrato cancelado com sucesso' });
        onClose(false);
      },
      onError: error => {
        setAlert({ show: true, msg: error.message });
        onClose(false);
      },
    },
  );

  function handleCloseAlert() {
    setAlert({ show: false, message: '' });
    queryCache.refetchQueries('fetch-contratos-bs');
  }

  return (
    <>
      <Dialog open={show} classes={{ paper: classes.paper }}>
        <DialogTitle>
          <Box>
            <Typography variant="body1">Atenção!</Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body2" className={classes.content}>
            Deseja continuar com o cancelamento do contrato de{' '}
            <span className={classes.dadosContratoStyle}>{data?.contraparte}</span> com o CNPJ:{' '}
            <span className={classes.dadosContratoStyle}>{formatCNPJ(data?.contraparteCNPJ)}</span> e vigência de{' '}
            <span className={classes.dadosContratoStyle}>{data?.periodoFornecimento}</span>?
          </Typography>
        </DialogContent>
        <DialogActions>
          {' '}
          <Button
            onClick={() => {
              onClose(false);
            }}
            color="primary"
          >
            Não
          </Button>
          <Button color="primary" onClick={() => cancelContract(data?.id)} autoFocus>
            Sim, continuar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={alert.show}
        autoHideDuration={3000}
        message={alert.msg}
        onClose={() => {
          handleCloseAlert();
        }}
      />
    </>
  );
};
