"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MigracaoAzTipoIncentivo = exports.MigracaoAzTipoProduto = exports.PropostaMigracaoAzStatus = exports.CoreContractStatus = exports.MigracaoAzStatus = exports.MigracaoAzStatusFatura = exports.MigracaoAzStatusInferido = exports.DownloadDocumentType = exports.MaritalStatus = exports.NomesPlanoGDLegiveis = exports.NomesPlanoGD = exports.TipoConexaoKwh = exports.GeracaoDistribuidaBackstageStatus = exports.ContratoGeracaoDistribuidaStatus = exports.GeracaoDistribuidaStatus = exports.DocumentoFaturaTipoConexaoEnum = exports.DocumentoFaturaSubGrupoEnum = exports.DocumentoFaturaSubGrupoBEnum = exports.DocumentoFaturaSubGrupoAEnum = exports.DocumentoFaturaGrupoEnum = exports.OrdenacaoPropostaContrato = exports.ProdutoInferido = exports.StatusMigracaoContato = exports.UsuarioOrAssinante = exports.OrderContratoPropostasTipo = exports.FiltrosStatusContratacao = exports.FluxoContratacaoStatus = exports.Persona = exports.FaturaStatus = exports.ContratoAutorizacaoStatus = exports.HistoricoAlteracaoUsuarioTipo = exports.HistoricoAlteracaoAcao = exports.TarifaTipo = exports.NivelTensaoTipo = exports.TipoTelefoneInfoEmpresa = exports.MedidorStatusEnum = exports.TipoAssinante = exports.PagamentoPrazoTipo = exports.AgenteCategoria = exports.AgenteStatus = exports.TipoGestao = exports.DocumentoStatus = exports.DocumentoTipo = exports.AnaliseCreditoSPRating = exports.AgenteAnaliseCreditoStatus = exports.AuthRoles = exports.PatamarCategoria = exports.FaturamentoStatus = exports.PeriodoRatingCredito = exports.TipoPremioPreco = exports.TipoPremioPrecoRating = exports.MercadoStatus = exports.ContratoStatus = exports.PropostaStatus = exports.TipoModulacao = exports.TipoSazonalidade = exports.TipoVariacao = exports.TipoFlexibilidade = exports.TipoPrazo = exports.Submercado = exports.GarantiaFinanceira = exports.TipoAssinanteRegra = exports.TipoOperacao = exports.FonteEnergia = exports.FiltroUnidade = void 0;
var FiltroUnidade;
(function (FiltroUnidade) {
    FiltroUnidade["MWM"] = "MWM";
    FiltroUnidade["MWH"] = "MWH";
    FiltroUnidade["VALOR"] = "VALOR";
})(FiltroUnidade = exports.FiltroUnidade || (exports.FiltroUnidade = {}));
var FonteEnergia;
(function (FonteEnergia) {
    FonteEnergia["CONVENCIONAL"] = "CONVENCIONAL";
    FonteEnergia["I0"] = "I0";
    FonteEnergia["I50"] = "I50";
    FonteEnergia["I100"] = "I100";
})(FonteEnergia = exports.FonteEnergia || (exports.FonteEnergia = {}));
var TipoOperacao;
(function (TipoOperacao) {
    TipoOperacao["COMPRA"] = "COMPRA";
    TipoOperacao["VENDA"] = "VENDA";
})(TipoOperacao = exports.TipoOperacao || (exports.TipoOperacao = {}));
var TipoAssinanteRegra;
(function (TipoAssinanteRegra) {
    TipoAssinanteRegra["REPRESENTANTE_LEGAL"] = "REPRESENTANTE_LEGAL";
    TipoAssinanteRegra["PROCURADOR"] = "PROCURADOR";
})(TipoAssinanteRegra = exports.TipoAssinanteRegra || (exports.TipoAssinanteRegra = {}));
var GarantiaFinanceira;
(function (GarantiaFinanceira) {
    GarantiaFinanceira["REGISTRO_POS_PAGAMENTO"] = "REGISTRO_POS_PAGAMENTO";
})(GarantiaFinanceira = exports.GarantiaFinanceira || (exports.GarantiaFinanceira = {}));
var Submercado;
(function (Submercado) {
    Submercado["SUDESTE"] = "SUDESTE";
    Submercado["SUL"] = "SUL";
    Submercado["NORDESTE"] = "NORDESTE";
    Submercado["NORTE"] = "NORTE";
})(Submercado = exports.Submercado || (exports.Submercado = {}));
var TipoPrazo;
(function (TipoPrazo) {
    TipoPrazo["CURTO_PRAZO"] = "CURTO_PRAZO";
    TipoPrazo["LONGO_PRAZO"] = "LONGO_PRAZO";
})(TipoPrazo = exports.TipoPrazo || (exports.TipoPrazo = {}));
var TipoFlexibilidade;
(function (TipoFlexibilidade) {
    TipoFlexibilidade["SMART_FLEX"] = "SMART_FLEX";
    TipoFlexibilidade["FLEX_PADRAO"] = "FLEX_PADRAO";
})(TipoFlexibilidade = exports.TipoFlexibilidade || (exports.TipoFlexibilidade = {}));
var TipoVariacao;
(function (TipoVariacao) {
    TipoVariacao["TOTAL"] = "TOTAL";
    TipoVariacao["FLEX_10"] = "FLEX_10";
    TipoVariacao["FLEX_15"] = "FLEX_15";
    TipoVariacao["FLEX_20"] = "FLEX_20";
    TipoVariacao["FLEX_30"] = "FLEX_30";
    TipoVariacao["SEM_VARIACAO"] = "SEM_VARIACAO";
})(TipoVariacao = exports.TipoVariacao || (exports.TipoVariacao = {}));
var TipoSazonalidade;
(function (TipoSazonalidade) {
    TipoSazonalidade["FLAT"] = "FLAT";
    TipoSazonalidade["SAZO_10"] = "SAZO_10";
})(TipoSazonalidade = exports.TipoSazonalidade || (exports.TipoSazonalidade = {}));
var TipoModulacao;
(function (TipoModulacao) {
    TipoModulacao["FLAT"] = "FLAT";
    TipoModulacao["GERACAO"] = "GERACAO";
    TipoModulacao["CARGA"] = "CARGA";
    TipoModulacao["MRE"] = "MRE";
})(TipoModulacao = exports.TipoModulacao || (exports.TipoModulacao = {}));
var PropostaStatus;
(function (PropostaStatus) {
    PropostaStatus["AGUARDANDO_CONTRATACAO"] = "AGUARDANDO_CONTRATACAO";
    PropostaStatus["CONTRATADO"] = "CONTRATADO";
    PropostaStatus["EXPIRADO"] = "EXPIRADO";
    PropostaStatus["INVALIDO"] = "INVALIDO";
    PropostaStatus["AGUARDANDO_ANALISE_CREDITO"] = "AGUARDANDO_ANALISE_CREDITO";
})(PropostaStatus = exports.PropostaStatus || (exports.PropostaStatus = {}));
var ContratoStatus;
(function (ContratoStatus) {
    ContratoStatus["AGUARDANDO_ACEITE"] = "AGUARDANDO_ACEITE";
    ContratoStatus["AGUARDANDO_ASSINATURA"] = "AGUARDANDO_ASSINATURA";
    ContratoStatus["AGUARDANDO_CONFIRMACAO"] = "AGUARDANDO_CONFIRMACAO";
    ContratoStatus["CONTRATADO"] = "CONTRATADO";
    ContratoStatus["INVALIDADO"] = "INVALIDADO";
    ContratoStatus["CANCELADO"] = "CANCELADO";
    ContratoStatus["AGUARDANDO_ASSINANTES"] = "AGUARDANDO_ASSINANTES";
})(ContratoStatus = exports.ContratoStatus || (exports.ContratoStatus = {}));
var MercadoStatus;
(function (MercadoStatus) {
    MercadoStatus["MARKET_OPENED"] = "MARKET_OPENED";
    MercadoStatus["MARKET_WEEK_CLOSED"] = "MARKET_WEEK_CLOSED";
    MercadoStatus["MARKET_WEEKEND_CLOSED"] = "MARKET_WEEKEND_CLOSED";
})(MercadoStatus = exports.MercadoStatus || (exports.MercadoStatus = {}));
var TipoPremioPrecoRating;
(function (TipoPremioPrecoRating) {
    TipoPremioPrecoRating["CREDITO_AAA"] = "CREDITO_AAA";
    TipoPremioPrecoRating["CREDITO_AA_MAIS"] = "CREDITO_AA_MAIS";
    TipoPremioPrecoRating["CREDITO_AA"] = "CREDITO_AA";
    TipoPremioPrecoRating["CREDITO_AA_MENOS"] = "CREDITO_AA_MENOS";
    TipoPremioPrecoRating["CREDITO_A_MAIS"] = "CREDITO_A_MAIS";
    TipoPremioPrecoRating["CREDITO_A"] = "CREDITO_A";
    TipoPremioPrecoRating["CREDITO_A_MENOS"] = "CREDITO_A_MENOS";
    TipoPremioPrecoRating["CREDITO_BBB_MAIS"] = "CREDITO_BBB_MAIS";
    TipoPremioPrecoRating["CREDITO_BBB"] = "CREDITO_BBB";
    TipoPremioPrecoRating["CREDITO_BBB_MENOS"] = "CREDITO_BBB_MENOS";
    TipoPremioPrecoRating["CREDITO_BB_MAIS"] = "CREDITO_BB_MAIS";
    TipoPremioPrecoRating["CREDITO_BB"] = "CREDITO_BB";
    TipoPremioPrecoRating["CREDITO_BB_MENOS"] = "CREDITO_BB_MENOS";
    TipoPremioPrecoRating["CREDITO_B_MAIS"] = "CREDITO_B_MAIS";
    TipoPremioPrecoRating["CREDITO_B"] = "CREDITO_B";
    TipoPremioPrecoRating["CREDITO_B_MENOS"] = "CREDITO_B_MENOS";
    TipoPremioPrecoRating["CREDITO_CCC_MAIS_MENOS"] = "CREDITO_CCC_MAIS_MENOS";
    TipoPremioPrecoRating["CREDITO_CC"] = "CREDITO_CC";
    TipoPremioPrecoRating["CREDITO_C"] = "CREDITO_C";
    TipoPremioPrecoRating["CREDITO_D"] = "CREDITO_D";
})(TipoPremioPrecoRating = exports.TipoPremioPrecoRating || (exports.TipoPremioPrecoRating = {}));
var TipoPremioPreco;
(function (TipoPremioPreco) {
    TipoPremioPreco["CREDITO_AAA"] = "CREDITO_AAA";
    TipoPremioPreco["CREDITO_AA_MAIS"] = "CREDITO_AA_MAIS";
    TipoPremioPreco["CREDITO_AA"] = "CREDITO_AA";
    TipoPremioPreco["CREDITO_AA_MENOS"] = "CREDITO_AA_MENOS";
    TipoPremioPreco["CREDITO_A_MAIS"] = "CREDITO_A_MAIS";
    TipoPremioPreco["CREDITO_A"] = "CREDITO_A";
    TipoPremioPreco["CREDITO_A_MENOS"] = "CREDITO_A_MENOS";
    TipoPremioPreco["CREDITO_BBB_MAIS"] = "CREDITO_BBB_MAIS";
    TipoPremioPreco["CREDITO_BBB"] = "CREDITO_BBB";
    TipoPremioPreco["CREDITO_BBB_MENOS"] = "CREDITO_BBB_MENOS";
    TipoPremioPreco["CREDITO_BB_MAIS"] = "CREDITO_BB_MAIS";
    TipoPremioPreco["CREDITO_BB"] = "CREDITO_BB";
    TipoPremioPreco["CREDITO_BB_MENOS"] = "CREDITO_BB_MENOS";
    TipoPremioPreco["CREDITO_B_MAIS"] = "CREDITO_B_MAIS";
    TipoPremioPreco["CREDITO_B"] = "CREDITO_B";
    TipoPremioPreco["CREDITO_B_MENOS"] = "CREDITO_B_MENOS";
    TipoPremioPreco["CREDITO_CCC_MAIS_MENOS"] = "CREDITO_CCC_MAIS_MENOS";
    TipoPremioPreco["CREDITO_CC"] = "CREDITO_CC";
    TipoPremioPreco["CREDITO_C"] = "CREDITO_C";
    TipoPremioPreco["CREDITO_D"] = "CREDITO_D";
    TipoPremioPreco["PRODUTO_COMUM"] = "PRODUTO_COMUM";
    TipoPremioPreco["PRODUTO_INCOMUM"] = "PRODUTO_INCOMUM";
    TipoPremioPreco["PRODUTO_RARO"] = "PRODUTO_RARO";
    TipoPremioPreco["TOTAL"] = "TOTAL";
    TipoPremioPreco["FLEX_10"] = "FLEX_10";
    TipoPremioPreco["FLEX_15"] = "FLEX_15";
    TipoPremioPreco["FLEX_20"] = "FLEX_20";
    TipoPremioPreco["FLEX_30"] = "FLEX_30";
    TipoPremioPreco["FLAT"] = "FLAT";
    TipoPremioPreco["PRODUTO_ILIQUIDO"] = "PRODUTO_ILIQUIDO";
    TipoPremioPreco["PRAZO_PAGAMENTO"] = "PRAZO_PAGAMENTO";
    TipoPremioPreco["ON_MARKET_HOURS"] = "ON_MARKET_HOURS";
    TipoPremioPreco["OFF_MARKET_HOURS"] = "OFF_MARKET_HOURS";
    TipoPremioPreco["OFF_MARKET_WEEKEND_HOURS"] = "OFF_MARKET_WEEKEND_HOURS";
    TipoPremioPreco["FLEXIBILIDADE_PAGAMENTO"] = "FLEXIBILIDADE_PAGAMENTO";
    TipoPremioPreco["CUSTO_OPERACIONAL"] = "CUSTO_OPERACIONAL";
})(TipoPremioPreco = exports.TipoPremioPreco || (exports.TipoPremioPreco = {}));
var PeriodoRatingCredito;
(function (PeriodoRatingCredito) {
    PeriodoRatingCredito["MES_ZERO"] = "MES_ZERO";
    PeriodoRatingCredito["MES_UM"] = "MES_UM";
    PeriodoRatingCredito["MES_DOIS"] = "MES_DOIS";
    PeriodoRatingCredito["MES_TRES"] = "MES_TRES";
    PeriodoRatingCredito["ANO_ZERO"] = "ANO_ZERO";
    PeriodoRatingCredito["ANO_UM"] = "ANO_UM";
    PeriodoRatingCredito["ANO_DOIS"] = "ANO_DOIS";
    PeriodoRatingCredito["ANO_TRES"] = "ANO_TRES";
    PeriodoRatingCredito["ANO_QUATRO"] = "ANO_QUATRO";
    PeriodoRatingCredito["ANO_CINCO"] = "ANO_CINCO";
})(PeriodoRatingCredito = exports.PeriodoRatingCredito || (exports.PeriodoRatingCredito = {}));
var FaturamentoStatus;
(function (FaturamentoStatus) {
    FaturamentoStatus["ABERTA"] = "ABERTA";
    FaturamentoStatus["FECHADA"] = "FECHADA";
    FaturamentoStatus["EM_ATRASO"] = "EM_ATRASO";
    FaturamentoStatus["PAGA"] = "PAGA";
})(FaturamentoStatus = exports.FaturamentoStatus || (exports.FaturamentoStatus = {}));
var PatamarCategoria;
(function (PatamarCategoria) {
    PatamarCategoria[PatamarCategoria["DIA_NAO_UTIL"] = 2] = "DIA_NAO_UTIL";
    PatamarCategoria[PatamarCategoria["DIA_UTIL"] = 1] = "DIA_UTIL";
})(PatamarCategoria = exports.PatamarCategoria || (exports.PatamarCategoria = {}));
var AuthRoles;
(function (AuthRoles) {
    AuthRoles["AGENTE_ADMINISTRADOR"] = "agente:administrador";
    AuthRoles["AGENTE_MEMBRO"] = "agente:membro";
    AuthRoles["AGENTE_REPRESENTANTE_LEGAL"] = "agente:representante-legal";
    AuthRoles["AGENTE_PROCURADOR"] = "agente:procurador";
    AuthRoles["BASIC_PLATFORM"] = "basic:platform";
})(AuthRoles = exports.AuthRoles || (exports.AuthRoles = {}));
var AgenteAnaliseCreditoStatus;
(function (AgenteAnaliseCreditoStatus) {
    AgenteAnaliseCreditoStatus["ANALISE_PENDENTE"] = "ANALISE_PENDENTE";
    AgenteAnaliseCreditoStatus["EM_APROVACAO"] = "EM_APROVACAO";
    AgenteAnaliseCreditoStatus["ANALISE_CONCLUIDA"] = "ANALISE_CONCLUIDA";
    AgenteAnaliseCreditoStatus["REPROVADA"] = "REPROVADA";
})(AgenteAnaliseCreditoStatus = exports.AgenteAnaliseCreditoStatus || (exports.AgenteAnaliseCreditoStatus = {}));
var AnaliseCreditoSPRating;
(function (AnaliseCreditoSPRating) {
    AnaliseCreditoSPRating["AAA"] = "AAA";
    AnaliseCreditoSPRating["AA_MAIS"] = "AA_MAIS";
    AnaliseCreditoSPRating["AA"] = "AA";
    AnaliseCreditoSPRating["AA_MENOS"] = "AA_MENOS";
    AnaliseCreditoSPRating["A_MAIS"] = "A_MAIS";
    AnaliseCreditoSPRating["A"] = "A";
    AnaliseCreditoSPRating["A_MENOS"] = "A_MENOS";
    AnaliseCreditoSPRating["BBB_MAIS"] = "BBB_MAIS";
    AnaliseCreditoSPRating["BBB"] = "BBB";
    AnaliseCreditoSPRating["BBB_MENOS"] = "BBB_MENOS";
    AnaliseCreditoSPRating["BB_MAIS"] = "BB_MAIS";
    AnaliseCreditoSPRating["BB"] = "BB";
    AnaliseCreditoSPRating["BB_MENOS"] = "BB_MENOS";
    AnaliseCreditoSPRating["B_MAIS"] = "B_MAIS";
    AnaliseCreditoSPRating["B"] = "B";
    AnaliseCreditoSPRating["B_MENOS"] = "B_MENOS";
    AnaliseCreditoSPRating["CCC_MAIS_MENOS"] = "CCC_MAIS_MENOS";
    AnaliseCreditoSPRating["CC"] = "CC";
    AnaliseCreditoSPRating["C"] = "C";
    AnaliseCreditoSPRating["D"] = "D";
})(AnaliseCreditoSPRating = exports.AnaliseCreditoSPRating || (exports.AnaliseCreditoSPRating = {}));
var DocumentoTipo;
(function (DocumentoTipo) {
    DocumentoTipo["BALANCO"] = "BALANCO";
    DocumentoTipo["BALANCO_CONTROLADORA"] = "BALANCO_CONTROLADORA";
    DocumentoTipo["BALANCO_DRE"] = "BALANCO_DRE";
    DocumentoTipo["DRE"] = "DRE";
    DocumentoTipo["CONTRATO_SOCIAL"] = "CONTRATO_SOCIAL";
    DocumentoTipo["PROCURACAO"] = "PROCURACAO";
    DocumentoTipo["FATURA_DISTRIBUIDORA"] = "FATURA_DISTRIBUIDORA";
    DocumentoTipo["FOTO_USUARIO"] = "FOTO_USUARIO";
    DocumentoTipo["LIMINAR"] = "LIMINAR";
    DocumentoTipo["ESTATUTO_SOCIAL"] = "ESTATUTO_SOCIAL";
    DocumentoTipo["ATA_ELEICAO"] = "ATA_ELEICAO";
    DocumentoTipo["ESTATUTO_SOCIAL_ATA_ELEICAO"] = "ESTATUTO_SOCIAL_ATA_ELEICAO";
    DocumentoTipo["CONTRATO_SOCIAL_ATA_ELEICAO"] = "CONTRATO_SOCIAL_ATA_ELEICAO";
    DocumentoTipo["CONTRATO_SOCIAL_CONTROLADORA"] = "CONTRATO_SOCIAL_CONTROLADORA";
    DocumentoTipo["ASSINANTE"] = "ASSINANTE";
})(DocumentoTipo = exports.DocumentoTipo || (exports.DocumentoTipo = {}));
var DocumentoStatus;
(function (DocumentoStatus) {
    DocumentoStatus["APROVADO"] = "APROVADO";
    DocumentoStatus["RECUSADO"] = "RECUSADO";
    DocumentoStatus["ILEGIVEL"] = "ILEGIVEL";
    DocumentoStatus["INVALIDO"] = "INVALIDO";
    DocumentoStatus["IRREGULAR"] = "IRREGULAR";
    DocumentoStatus["PENDENTE_ENVIO"] = "PENDENTE_ENVIO";
    DocumentoStatus["PENDENTE_AVALIACAO"] = "PENDENTE_AVALIACAO";
    DocumentoStatus["IGNORADO"] = "IGNORADO";
})(DocumentoStatus = exports.DocumentoStatus || (exports.DocumentoStatus = {}));
var TipoGestao;
(function (TipoGestao) {
    TipoGestao["GESTAO_CCEE"] = "GESTAO_CCEE";
    TipoGestao["GESTAO_COMPLETA"] = "GESTAO_COMPLETA";
})(TipoGestao = exports.TipoGestao || (exports.TipoGestao = {}));
var AgenteStatus;
(function (AgenteStatus) {
    AgenteStatus["PENDENTE"] = "PENDENTE";
    AgenteStatus["EM_ANALISE"] = "EM_ANALISE";
    AgenteStatus["CADASTRADO"] = "CADASTRADO";
    AgenteStatus["ACESSO_RESTRITO"] = "ACESSO_RESTRITO";
})(AgenteStatus = exports.AgenteStatus || (exports.AgenteStatus = {}));
var AgenteCategoria;
(function (AgenteCategoria) {
    AgenteCategoria["CONSUMIDOR"] = "CONSUMIDOR";
    AgenteCategoria["NAO_CONSUMIDOR"] = "NAO_CONSUMIDOR";
})(AgenteCategoria = exports.AgenteCategoria || (exports.AgenteCategoria = {}));
var PagamentoPrazoTipo;
(function (PagamentoPrazoTipo) {
    PagamentoPrazoTipo["DIAS_UTEIS"] = "DIAS_UTEIS";
    PagamentoPrazoTipo["DIAS_CORRIDOS"] = "DIAS_CORRIDOS";
})(PagamentoPrazoTipo = exports.PagamentoPrazoTipo || (exports.PagamentoPrazoTipo = {}));
var TipoAssinante;
(function (TipoAssinante) {
    TipoAssinante["REPRESENTANTE_LEGAL"] = "REPRESENTANTE_LEGAL";
    TipoAssinante["PROCURADOR_EMPRESA"] = "PROCURADOR_EMPRESA";
    TipoAssinante["PROCURADOR_REPRESENTANTE_LEGAL"] = "PROCURADOR_REPRESENTANTE_LEGAL";
})(TipoAssinante = exports.TipoAssinante || (exports.TipoAssinante = {}));
var MedidorStatusEnum;
(function (MedidorStatusEnum) {
    MedidorStatusEnum["NAO_VALIDADO"] = "NAO_VALIDADO";
    MedidorStatusEnum["VALIDADO"] = "VALIDADO";
    MedidorStatusEnum["INVALIDO"] = "INVALIDO";
})(MedidorStatusEnum = exports.MedidorStatusEnum || (exports.MedidorStatusEnum = {}));
var TipoTelefoneInfoEmpresa;
(function (TipoTelefoneInfoEmpresa) {
    TipoTelefoneInfoEmpresa["TELEFONE"] = "TELEFONE";
    TipoTelefoneInfoEmpresa["FAX"] = "FAX";
})(TipoTelefoneInfoEmpresa = exports.TipoTelefoneInfoEmpresa || (exports.TipoTelefoneInfoEmpresa = {}));
var NivelTensaoTipo;
(function (NivelTensaoTipo) {
    NivelTensaoTipo["A1"] = "A1";
    NivelTensaoTipo["A2"] = "A2";
    NivelTensaoTipo["A3"] = "A3";
    NivelTensaoTipo["A3a"] = "A3a";
    NivelTensaoTipo["A4"] = "A4";
    NivelTensaoTipo["AS"] = "AS";
})(NivelTensaoTipo = exports.NivelTensaoTipo || (exports.NivelTensaoTipo = {}));
var TarifaTipo;
(function (TarifaTipo) {
    TarifaTipo["VERDE"] = "VERDE";
    TarifaTipo["AZUL"] = "AZUL";
    TarifaTipo["CONVENCIONAL"] = "CONVENCIONAL";
})(TarifaTipo = exports.TarifaTipo || (exports.TarifaTipo = {}));
var HistoricoAlteracaoAcao;
(function (HistoricoAlteracaoAcao) {
    HistoricoAlteracaoAcao["PAGAMENTO_CONTRATO"] = "PAGAMENTO_CONTRATO";
    HistoricoAlteracaoAcao["PRECO_PROPOSTA"] = "PRECO_PROPOSTA";
    HistoricoAlteracaoAcao["RECALCULO_PROPOSTA"] = "RECALCULO_PROPOSTA";
    HistoricoAlteracaoAcao["ANALISE_CREDITO"] = "ANALISE_CREDITO";
    HistoricoAlteracaoAcao["REPROVACAO_CADASTRO"] = "REPROVACAO_CADASTRO";
    HistoricoAlteracaoAcao["CONTRATANTE_CONTRATO"] = "CONTRATANTE_CONTRATO";
    HistoricoAlteracaoAcao["DATA_BASE_PROPOSTA"] = "DATA_BASE_PROPOSTA";
    HistoricoAlteracaoAcao["DATA_ASSINATURA_CONTRATO"] = "DATA_ASSINATURA_CONTRATO";
    HistoricoAlteracaoAcao["PERCENTUAL_ATENDIMENTO"] = "PERCENTUAL_ATENDIMENTO";
    HistoricoAlteracaoAcao["CRIACAO_PROPOSTA"] = "CRIACAO_PROPOSTA";
    HistoricoAlteracaoAcao["CANCELAMENTO_CONTRATO"] = "CANCELAMENTO_CONTRATO";
})(HistoricoAlteracaoAcao = exports.HistoricoAlteracaoAcao || (exports.HistoricoAlteracaoAcao = {}));
var HistoricoAlteracaoUsuarioTipo;
(function (HistoricoAlteracaoUsuarioTipo) {
    HistoricoAlteracaoUsuarioTipo["BACKSTAGE"] = "BACKSTAGE";
    HistoricoAlteracaoUsuarioTipo["DIGITAL_COM"] = "DIGITAL_COM";
})(HistoricoAlteracaoUsuarioTipo = exports.HistoricoAlteracaoUsuarioTipo || (exports.HistoricoAlteracaoUsuarioTipo = {}));
var ContratoAutorizacaoStatus;
(function (ContratoAutorizacaoStatus) {
    ContratoAutorizacaoStatus["PENDENTE"] = "PENDENTE";
    ContratoAutorizacaoStatus["ACEITO"] = "ACEITO";
    ContratoAutorizacaoStatus["RECUSADO"] = "RECUSADO";
})(ContratoAutorizacaoStatus = exports.ContratoAutorizacaoStatus || (exports.ContratoAutorizacaoStatus = {}));
var FaturaStatus;
(function (FaturaStatus) {
    FaturaStatus["ABERTA"] = "ABERTA";
    FaturaStatus["EM_ATRASO"] = "EM_ATRASO";
    FaturaStatus["FECHADA"] = "FECHADA";
    FaturaStatus["PAGA"] = "PAGA";
})(FaturaStatus = exports.FaturaStatus || (exports.FaturaStatus = {}));
var Persona;
(function (Persona) {
    Persona["INDEPENDENTE"] = "INDEPENDENTE";
    Persona["MIGRACAO"] = "MIGRACAO";
    Persona["ZEN"] = "ZEN";
    Persona["CATIVO"] = "CATIVO";
})(Persona = exports.Persona || (exports.Persona = {}));
var FluxoContratacaoStatus;
(function (FluxoContratacaoStatus) {
    FluxoContratacaoStatus["AGUARDANDO_ASSINATURA"] = "AGUARDANDO_ASSINATURA";
    FluxoContratacaoStatus["CANCELADO"] = "CANCELADO";
    FluxoContratacaoStatus["CONTRATADO"] = "CONTRATADO";
    FluxoContratacaoStatus["CREDITO_INSUFICIENTE"] = "CREDITO_INSUFICIENTE";
    FluxoContratacaoStatus["CREDITO_INSUFICIENTE_E_SEM_FATURA"] = "CREDITO_INSUFICIENTE_E_SEM_FATURA";
    FluxoContratacaoStatus["DOCUMENTO_COM_PENDENCIA"] = "DOCUMENTO_COM_PENDENCIA";
    FluxoContratacaoStatus["DOCUMENTO_CONSTITUICAO_EM_ANALISE"] = "DOCUMENTO_CONSTITUICAO_EM_ANALISE";
    FluxoContratacaoStatus["DOCUMENTO_FINANCEIRO_EM_ANALISE"] = "DOCUMENTO_FINANCEIRO_EM_ANALISE";
    FluxoContratacaoStatus["DOCUMENTO_FATURA_EM_ANALISE"] = "DOCUMENTO_FATURA_EM_ANALISE";
    FluxoContratacaoStatus["ENCERRADO"] = "ENCERRADO";
    FluxoContratacaoStatus["ENVIAR_DOCUMENTO_CONSTITUICAO"] = "ENVIAR_DOCUMENTO_CONSTITUICAO";
    FluxoContratacaoStatus["ENVIAR_DOCUMENTO_FATURA"] = "ENVIAR_DOCUMENTO_FATURA";
    FluxoContratacaoStatus["EXPIRADO"] = "EXPIRADO";
    FluxoContratacaoStatus["SOLICITAR_ASSINATURAS"] = "SOLICITAR_ASSINATURAS";
    FluxoContratacaoStatus["VALIDA"] = "VALIDA";
    FluxoContratacaoStatus["VIGENTE"] = "VIGENTE";
})(FluxoContratacaoStatus = exports.FluxoContratacaoStatus || (exports.FluxoContratacaoStatus = {}));
var FiltrosStatusContratacao;
(function (FiltrosStatusContratacao) {
    FiltrosStatusContratacao["PROPOSTA_VALIDA"] = "PROPOSTA_VALIDA";
    FiltrosStatusContratacao["PROPOSTA_EXPIRADA"] = "PROPOSTA_EXPIRADA";
    FiltrosStatusContratacao["EXCEDENTE_AO_CREDITO"] = "EXCEDENTE_AO_CREDITO";
    FiltrosStatusContratacao["ASSINATURA_NAO_SOLICITADA"] = "ASSINATURA_NAO_SOLICITADA";
    FiltrosStatusContratacao["AGUARDANDO_ASSINATURA"] = "AGUARDANDO_ASSINATURA";
    FiltrosStatusContratacao["CONTRATO_ASSINADO"] = "CONTRATO_ASSINADO";
    FiltrosStatusContratacao["CONTRATO_VIGENTE"] = "CONTRATO_VIGENTE";
    FiltrosStatusContratacao["CONTRATO_ENCERRADO"] = "CONTRATO_ENCERRADO";
    FiltrosStatusContratacao["PROPOSTA_EXPIRA_EM_BREVE"] = "PROPOSTA_EXPIRA_EM_BREVE";
    FiltrosStatusContratacao["CONTRATOS_VENCEM_HOJE"] = "CONTRATOS_VENCEM_HOJE";
    FiltrosStatusContratacao["CONTRATO_CANCELADO"] = "CONTRATO_CANCELADO";
    FiltrosStatusContratacao["CONTRATOS_ASSINADOS_MES_ATUAL"] = "CONTRATOS_ASSINADOS_MES_ATUAL";
    FiltrosStatusContratacao["CONTRATOS_ASSINADOS_ANO_ATUAL"] = "CONTRATOS_ASSINADOS_ANO_ATUAL";
})(FiltrosStatusContratacao = exports.FiltrosStatusContratacao || (exports.FiltrosStatusContratacao = {}));
var OrderContratoPropostasTipo;
(function (OrderContratoPropostasTipo) {
    OrderContratoPropostasTipo["CONTRAPARTE"] = "contraparte";
    OrderContratoPropostasTipo["PRECOMEDIO"] = "precoMedio";
    OrderContratoPropostasTipo["PRODUTO"] = "produto";
    OrderContratoPropostasTipo["STATUS"] = "status";
    OrderContratoPropostasTipo["DATABASE"] = "dataBase";
    OrderContratoPropostasTipo["UPDATEDAT"] = "updatedAt";
})(OrderContratoPropostasTipo = exports.OrderContratoPropostasTipo || (exports.OrderContratoPropostasTipo = {}));
var UsuarioOrAssinante;
(function (UsuarioOrAssinante) {
    UsuarioOrAssinante["USUARIO"] = "USUARIO";
    UsuarioOrAssinante["ASSINANTE"] = "ASSINANTE";
})(UsuarioOrAssinante = exports.UsuarioOrAssinante || (exports.UsuarioOrAssinante = {}));
var StatusMigracaoContato;
(function (StatusMigracaoContato) {
    StatusMigracaoContato["PRE-QUALIFICADO"] = "PRE-QUALIFICADO";
    StatusMigracaoContato["DESQUALIFICADO"] = "DESQUALIFICADO";
})(StatusMigracaoContato = exports.StatusMigracaoContato || (exports.StatusMigracaoContato = {}));
var ProdutoInferido;
(function (ProdutoInferido) {
    ProdutoInferido["MERCADO_LIVRE_ENERGIA"] = "MERCADO_LIVRE_ENERGIA";
    ProdutoInferido["MIGRACAO_AZ"] = "MIGRACAO_AZ";
    ProdutoInferido["GERACAO_DISTRIBUIDA"] = "GERACAO_DISTRIBUIDA";
    ProdutoInferido["GERACAO_DISTRIBUIDA_PF"] = "GERACAO_DISTRIBUIDA_PF";
    ProdutoInferido["INDEFINIDO"] = "INDEFINIDO";
})(ProdutoInferido = exports.ProdutoInferido || (exports.ProdutoInferido = {}));
var OrdenacaoPropostaContrato;
(function (OrdenacaoPropostaContrato) {
    OrdenacaoPropostaContrato["UPDATEDAT"] = "UPDATEDAT";
    OrdenacaoPropostaContrato["PROPOSTA_EXPIRADA"] = "PROPOSTA_EXPIRADA";
    OrdenacaoPropostaContrato["PROPOSTA_EXPIRA_EM_BREVE"] = "PROPOSTA_EXPIRA_EM_BREVE";
    OrdenacaoPropostaContrato["CONTRATO_ASSINADO"] = "CONTRATO_ASSINADO";
    OrdenacaoPropostaContrato["CONTRATO_CANCELADO"] = "CONTRATO_CANCELADO";
    OrdenacaoPropostaContrato["CONTRATO_VIGENTE"] = "CONTRATO_VIGENTE";
    OrdenacaoPropostaContrato["CONTRATO_EXPIRA_EM_BREVE"] = "CONTRATO_EXPIRA_EM_BREVE";
})(OrdenacaoPropostaContrato = exports.OrdenacaoPropostaContrato || (exports.OrdenacaoPropostaContrato = {}));
var DocumentoFaturaGrupoEnum;
(function (DocumentoFaturaGrupoEnum) {
    DocumentoFaturaGrupoEnum["A"] = "A";
    DocumentoFaturaGrupoEnum["B"] = "B";
})(DocumentoFaturaGrupoEnum = exports.DocumentoFaturaGrupoEnum || (exports.DocumentoFaturaGrupoEnum = {}));
var DocumentoFaturaSubGrupoAEnum;
(function (DocumentoFaturaSubGrupoAEnum) {
    DocumentoFaturaSubGrupoAEnum["A1"] = "A1";
    DocumentoFaturaSubGrupoAEnum["A2"] = "A2";
    DocumentoFaturaSubGrupoAEnum["A3"] = "A3";
    DocumentoFaturaSubGrupoAEnum["A4"] = "A4";
})(DocumentoFaturaSubGrupoAEnum = exports.DocumentoFaturaSubGrupoAEnum || (exports.DocumentoFaturaSubGrupoAEnum = {}));
var DocumentoFaturaSubGrupoBEnum;
(function (DocumentoFaturaSubGrupoBEnum) {
    DocumentoFaturaSubGrupoBEnum["B1"] = "B1";
    DocumentoFaturaSubGrupoBEnum["B2"] = "B2";
    DocumentoFaturaSubGrupoBEnum["B3"] = "B3";
    DocumentoFaturaSubGrupoBEnum["B4"] = "B4";
    DocumentoFaturaSubGrupoBEnum["B_OPTANTE"] = "B Optante";
})(DocumentoFaturaSubGrupoBEnum = exports.DocumentoFaturaSubGrupoBEnum || (exports.DocumentoFaturaSubGrupoBEnum = {}));
var DocumentoFaturaSubGrupoEnum;
(function (DocumentoFaturaSubGrupoEnum) {
    DocumentoFaturaSubGrupoEnum["B1"] = "B1";
    DocumentoFaturaSubGrupoEnum["B2"] = "B2";
    DocumentoFaturaSubGrupoEnum["B3"] = "B3";
    DocumentoFaturaSubGrupoEnum["B4"] = "B4";
    DocumentoFaturaSubGrupoEnum["B_OPTANTE"] = "B Optante";
    DocumentoFaturaSubGrupoEnum["A1"] = "A1";
    DocumentoFaturaSubGrupoEnum["A2"] = "A2";
    DocumentoFaturaSubGrupoEnum["A3"] = "A3";
    DocumentoFaturaSubGrupoEnum["A4"] = "A4";
})(DocumentoFaturaSubGrupoEnum = exports.DocumentoFaturaSubGrupoEnum || (exports.DocumentoFaturaSubGrupoEnum = {}));
var DocumentoFaturaTipoConexaoEnum;
(function (DocumentoFaturaTipoConexaoEnum) {
    DocumentoFaturaTipoConexaoEnum["MONOFASICO"] = "MONOF\u00C1SICO";
    DocumentoFaturaTipoConexaoEnum["BIFASICO"] = "BIF\u00C1SICO";
    DocumentoFaturaTipoConexaoEnum["TRIFASICO"] = "TRIF\u00C1SICO";
})(DocumentoFaturaTipoConexaoEnum = exports.DocumentoFaturaTipoConexaoEnum || (exports.DocumentoFaturaTipoConexaoEnum = {}));
var GeracaoDistribuidaStatus;
(function (GeracaoDistribuidaStatus) {
    GeracaoDistribuidaStatus["DISTRIBUIDORA_NAO_ATENDIDA"] = "DISTRIBUIDORA_NAO_ATENDIDA";
    GeracaoDistribuidaStatus["VOLUME_DISTRIBUIDORA_INDISPONIVEL"] = "VOLUME_DISTRIBUIDORA_INDISPONIVEL";
    GeracaoDistribuidaStatus["ENVIAR_FATURA"] = "ENVIAR_FATURA";
    GeracaoDistribuidaStatus["FATURA_EM_ANALISE"] = "FATURA_EM_ANALISE";
    GeracaoDistribuidaStatus["FATURA_CLIENTE_NAO_ATENDIDA"] = "FATURA_CLIENTE_NAO_ATENDIDA";
    GeracaoDistribuidaStatus["FATURA_VALIDADA"] = "FATURA_VALIDADA";
    GeracaoDistribuidaStatus["AGUARDANDO_ASSINANTES"] = "AGUARDANDO_ASSINANTES";
    GeracaoDistribuidaStatus["ENVIAR_DOCUMENTOS_CONSTITUICAO"] = "ENVIAR_DOCUMENTOS_CONSTITUICAO";
    GeracaoDistribuidaStatus["ENVIAR_DOCUMENTOS_CONSTITUICAO_ASSINADO"] = "ENVIAR_DOCUMENTOS_CONSTITUICAO_ASSINADO";
    GeracaoDistribuidaStatus["AGUARDANDO_ASSINATURAS"] = "AGUARDANDO_ASSINATURAS";
    GeracaoDistribuidaStatus["ASSINATURAS_EM_ANALISE"] = "ASSINATURAS_EM_ANALISE";
    GeracaoDistribuidaStatus["ASSINATURAS_NAO_CONFEREM"] = "ASSINATURAS_NAO_CONFEREM";
    GeracaoDistribuidaStatus["CANCELADO"] = "CANCELADO";
    GeracaoDistribuidaStatus["CREDITO_EM_ANALISE"] = "CREDITO_EM_ANALISE";
    GeracaoDistribuidaStatus["CREDITO_INSUFICIENTE"] = "CREDITO_INSUFICIENTE";
    GeracaoDistribuidaStatus["NAO_ADERENTE"] = "NAO_ADERENTE";
    GeracaoDistribuidaStatus["ADERENTE"] = "ADERENTE";
})(GeracaoDistribuidaStatus = exports.GeracaoDistribuidaStatus || (exports.GeracaoDistribuidaStatus = {}));
var ContratoGeracaoDistribuidaStatus;
(function (ContratoGeracaoDistribuidaStatus) {
    ContratoGeracaoDistribuidaStatus["AGUARDANDO_ASSINANTES"] = "AGUARDANDO_ASSINANTES";
    ContratoGeracaoDistribuidaStatus["AGUARDANDO_ASSINATURAS"] = "AGUARDANDO_ASSINATURAS";
    ContratoGeracaoDistribuidaStatus["AGUARDANDO_CREDITO"] = "AGUARDANDO_CREDITO";
    ContratoGeracaoDistribuidaStatus["INVALIDADO"] = "INVALIDADO";
    ContratoGeracaoDistribuidaStatus["CONTRATADO"] = "CONTRATADO";
    ContratoGeracaoDistribuidaStatus["CANCELADO"] = "CANCELADO";
})(ContratoGeracaoDistribuidaStatus = exports.ContratoGeracaoDistribuidaStatus || (exports.ContratoGeracaoDistribuidaStatus = {}));
var GeracaoDistribuidaBackstageStatus;
(function (GeracaoDistribuidaBackstageStatus) {
    GeracaoDistribuidaBackstageStatus["ASSINATURA_NAO_SOLICITADA"] = "Assinatura(s) n\u00E3o-solicitada(s)";
    GeracaoDistribuidaBackstageStatus["AGUARDANDO_ASSINATURA"] = "Aguardando assinatura(s)";
    GeracaoDistribuidaBackstageStatus["AGUARDANDO_VALIDACAO"] = "Aguardando valida\u00E7\u00E3o";
    GeracaoDistribuidaBackstageStatus["EM_PROCESSO_ADERENCIA"] = "Em processo de ader\u00EAncia";
    GeracaoDistribuidaBackstageStatus["CONTRATO_VIGENTE"] = "Contrato vigente";
    GeracaoDistribuidaBackstageStatus["CANCELADO"] = "Cancelado";
    GeracaoDistribuidaBackstageStatus["CREDITO_INSUFICIENTE"] = "Cr\u00E9dito insuficiente";
})(GeracaoDistribuidaBackstageStatus = exports.GeracaoDistribuidaBackstageStatus || (exports.GeracaoDistribuidaBackstageStatus = {}));
var TipoConexaoKwh;
(function (TipoConexaoKwh) {
    TipoConexaoKwh[TipoConexaoKwh["MONOF\u00C1SICO"] = 30] = "MONOF\u00C1SICO";
    TipoConexaoKwh[TipoConexaoKwh["BIF\u00C1SICO"] = 50] = "BIF\u00C1SICO";
    TipoConexaoKwh[TipoConexaoKwh["TRIF\u00C1SICO"] = 100] = "TRIF\u00C1SICO";
})(TipoConexaoKwh = exports.TipoConexaoKwh || (exports.TipoConexaoKwh = {}));
var NomesPlanoGD;
(function (NomesPlanoGD) {
    NomesPlanoGD["PLANO_BASICO"] = "PLANO_BASICO";
})(NomesPlanoGD = exports.NomesPlanoGD || (exports.NomesPlanoGD = {}));
var NomesPlanoGDLegiveis;
(function (NomesPlanoGDLegiveis) {
    NomesPlanoGDLegiveis["PLANO_BASICO"] = "B\u00E1sico";
})(NomesPlanoGDLegiveis = exports.NomesPlanoGDLegiveis || (exports.NomesPlanoGDLegiveis = {}));
var MaritalStatus;
(function (MaritalStatus) {
    MaritalStatus["SINGLE"] = "SINGLE";
    MaritalStatus["MARRIED"] = "MARRIED";
    MaritalStatus["SEPARATED"] = "SEPARATED";
    MaritalStatus["DIVORCED"] = "DIVORCED";
    MaritalStatus["WIDOWER"] = "WIDOWER";
})(MaritalStatus = exports.MaritalStatus || (exports.MaritalStatus = {}));
var DownloadDocumentType;
(function (DownloadDocumentType) {
    DownloadDocumentType["CONTRATO"] = "contrato";
    DownloadDocumentType["PROCURACAO"] = "procuracao";
})(DownloadDocumentType = exports.DownloadDocumentType || (exports.DownloadDocumentType = {}));
var MigracaoAzStatusInferido;
(function (MigracaoAzStatusInferido) {
    MigracaoAzStatusInferido["ESCOLHER_PLANO"] = "ESCOLHER_PLANO";
    MigracaoAzStatusInferido["ESTUDO_VIABILIDADE"] = "ESTUDO_VIABILIDADE";
    MigracaoAzStatusInferido["AGUARDANDO_ASSINATURA"] = "AGUARDANDO_ASSINATURA";
    MigracaoAzStatusInferido["CONTRATADO"] = "CONTRATADO";
    MigracaoAzStatusInferido["CANCELADO"] = "CANCELADO";
})(MigracaoAzStatusInferido = exports.MigracaoAzStatusInferido || (exports.MigracaoAzStatusInferido = {}));
var MigracaoAzStatusFatura;
(function (MigracaoAzStatusFatura) {
    MigracaoAzStatusFatura["ENVIAR_FATURA"] = "ENVIAR_FATURA";
    MigracaoAzStatusFatura["FATURA_EM_ANALISE"] = "FATURA_EM_ANALISE";
    MigracaoAzStatusFatura["FATURA_VALIDADA"] = "FATURA_VALIDADA";
    MigracaoAzStatusFatura["FATURA_INVALIDADA"] = "FATURA_INVALIDADA";
})(MigracaoAzStatusFatura = exports.MigracaoAzStatusFatura || (exports.MigracaoAzStatusFatura = {}));
var MigracaoAzStatus;
(function (MigracaoAzStatus) {
    MigracaoAzStatus["AGUARDANDO_ASSINATURA"] = "AGUARDANDO_ASSINATURA";
    MigracaoAzStatus["CONTRATO_ASSINADO"] = "CONTRATO_ASSINADO";
    MigracaoAzStatus["EM_MIGRACAO"] = "EM_MIGRACAO";
    MigracaoAzStatus["CANCELADO"] = "CANCELADO";
})(MigracaoAzStatus = exports.MigracaoAzStatus || (exports.MigracaoAzStatus = {}));
var CoreContractStatus;
(function (CoreContractStatus) {
    CoreContractStatus["AGUARDANDO_ASSINATURA"] = "awaiting_signatures";
    CoreContractStatus["CONTRATO_ASSINADO"] = "signed";
    CoreContractStatus["EM_MIGRACAO"] = "in_transition";
    CoreContractStatus["CANCELADO"] = "cancelled";
})(CoreContractStatus = exports.CoreContractStatus || (exports.CoreContractStatus = {}));
var PropostaMigracaoAzStatus;
(function (PropostaMigracaoAzStatus) {
    PropostaMigracaoAzStatus["AGUARDANDO_CONTRATACAO"] = "AGUARDANDO_CONTRATACAO";
    PropostaMigracaoAzStatus["CONTRATADO"] = "CONTRATADO";
    PropostaMigracaoAzStatus["CANCELADO"] = "CANCELADO";
})(PropostaMigracaoAzStatus = exports.PropostaMigracaoAzStatus || (exports.PropostaMigracaoAzStatus = {}));
var MigracaoAzTipoProduto;
(function (MigracaoAzTipoProduto) {
    MigracaoAzTipoProduto["DESCONTO_GARANTIDO"] = "DESCONTO_GARANTIDO";
    MigracaoAzTipoProduto["PRECO_FIXO"] = "PRECO_FIXO";
})(MigracaoAzTipoProduto = exports.MigracaoAzTipoProduto || (exports.MigracaoAzTipoProduto = {}));
var MigracaoAzTipoIncentivo;
(function (MigracaoAzTipoIncentivo) {
    MigracaoAzTipoIncentivo["CUSTO_INSTALACAO"] = "CUSTO_INSTALACAO";
    MigracaoAzTipoIncentivo["CASHBACK"] = "CASHBACK";
})(MigracaoAzTipoIncentivo = exports.MigracaoAzTipoIncentivo || (exports.MigracaoAzTipoIncentivo = {}));
